import "./App.css";
import Navigation from "./navigation";
import { ApiProvider } from "./providers/Api";
import { WalletProvider } from "./providers/Wallet";

function App() {
  return (
    <ApiProvider>
      <WalletProvider>
        <Navigation />
      </WalletProvider>
    </ApiProvider>
  );
}

export default App;
