import NavBarView from "./Navbar_View";
import useWallet from "../../hooks/useWallet";
import WalletTypes from "../../Types/Wallet";

const Navbar: React.FC = () => {
  const { connect, disconnect, account } = useWallet();

  const connectMetamask = async () => {
    await connect(WalletTypes.Metamask);
  };

  const disconnectWallet = async () => {
    await disconnect();
  };

  return (
    <>
      <NavBarView
        disconnectWallet={disconnectWallet}
        account={account}
        connectMetamask={connectMetamask}
      />
    </>
  );
};

export default Navbar;
