import React from "react";
import { Navbar, Project } from "../../components";

const AdminPage: React.FC = () => {
  return (
    <div className="relative min-h-screen h-full bg-blue-200">
        <header className="w-full bg-background">
          <Navbar />
        </header>
        <main className="pb-20">
          <Project />
        </main>
        <div className="absolute bottom-0 left-1/2">
          <img
            className="h-14 mt-2 mb-2 relative right-7"
            src="images/dltx_logo.png"
            alt="DLTx"
          />
        </div>
    </div>
  );
};

export default AdminPage;
