import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AdminPage from "./pages/Admin/Admin_Logic";

const Navigation = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" component={AdminPage} exact />
      </Switch>
    </Router>
  );
};

export default Navigation;
