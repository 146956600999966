import React from "react";
import { ethers } from "ethers";
import { Button } from "..";
import { JobTable } from "..";
import { ProjectStatus, JobStatus } from "../../Types/Status";

type LoadingType = {
  createProject: boolean;
  refreshProject: boolean;
  updateProject: boolean;
};

type Props = {
  getProjects(): Promise<void>;
  projects: api.Project[] | undefined;
  currentProject: api.Project | undefined;
  setCurrentProject(project?: api.Project);
  loadingData: boolean;
  errorMessage: string | undefined;
  createNewProject(): void;
  loading: LoadingType;
  updateProject(projectId: number | undefined);
  setErrorMessage: any;
};

const ProjectView: React.FC<Props> = (props) => {
  const projects = props.projects?.map((project) => (
    <option key={project.name}>{project.name}</option>
  ));

  const projectTotal = props.currentProject?.jobs.reduce((sum, job) => {
    return sum.add(job.amount);
  }, ethers.constants.Zero);

  return (
    <>
      <div className="flex content-start h-24 w-full items-center">
        <div className="flex-grow"></div>
        <select
          className="w-1/4 border-2 border-gray-600 mr-20 h-8"
          onChange={(project) => {
            const _project = props.projects?.find(
              (p) => p.name.trim() === project.target.value
            );
            props.setCurrentProject(_project);
          }}
        >
          {projects}
        </select>
        <div className="pr-8">Status:</div>
        <div className="border-2 border-gray-600 rounded-lg bg-white px-8 py-1 mr-20">
          {props.loadingData
            ? "Unknown"
            : props.currentProject
            ? ProjectStatus[props.currentProject.status]
            : "Unknown"}
        </div>
      </div>
      <div className="flex flex-row-reverse mb-4">
        {props.currentProject?.jobs.every(
          (job) => job.status === JobStatus.Paid
        ) &&
          props.currentProject.status !== ProjectStatus.Done && (
            <Button
              className="border-2 border-gray-600 rounded-lg bg-white px-8 py-1 mr-20"
              onClick={() => props.updateProject(props.currentProject?.id)}
            >
              {props.loading.updateProject ? (
                <span>Sending transaction</span>
              ) : (
                <span>Finish Project</span>
              )}
            </Button>
          )}

        <Button
          className="border-2 border-gray-600 rounded-lg bg-white px-8 py-1 mr-20"
          onClick={props.getProjects}
        >
          {props.loading.refreshProject ? (
            <span>Loading</span>
          ) : (
            <span>Fetch Project</span>
          )}
        </Button>
        {props.errorMessage ? (
          <div className="font-bold h-10 py-1 px-4 mr-4 text-red-700 bg-white rounded-lg border-2 border-gray-600">
            {props.errorMessage}
          </div>
        ) : (
          <div />
        )}
        <Button
          className="border-2 border-gray-600 rounded-lg bg-white px-8 py-1 mr-20"
          onClick={props.createNewProject}
        >
          {props.loading.createProject ? (
            <span>Creating</span>
          ) : (
            <span>Create new project</span>
          )}
        </Button>
        <div className="flex flex-grow pl-10">
          <div className="pr-4">
            <span className="block">Total value:</span>
            <span className="block">Retention value:</span>
          </div>
          <div>
            <span className="block">
              {props.currentProject &&
                `${projectTotal?.toNumber().toLocaleString()} SVG`}
            </span>

            <span className="block">
              {props.currentProject &&
                `${projectTotal?.div(20).toNumber().toLocaleString()} SVG`}
            </span>
          </div>
        </div>
      </div>
      <div className="mx-10">
        <JobTable
          project={props.currentProject}
          getProjects={props.getProjects}
          setErrorMessage={props.setErrorMessage}
        />
      </div>
    </>
  );
};

export default ProjectView;
