import JobTableView from "./JobTable_View";
import useApi from "../../hooks/useApi";
import useWallet from "../../hooks/useWallet";
import { ethers } from "ethers";
import Servgard from "../../build/contracts/Servgard.sol/Servgard.json";
import { JobStatus } from "../../Types/Status";

type props = {
  project: api.Project | undefined;
  getProjects(): Promise<void>;
  setErrorMessage: any;
};

const JobTableLogic: React.FC<props> = (props) => {
  const api = useApi();
  const { getSigner } = useWallet();
  const getContract = async () => {
    const signer: any = await getSigner();
    const contract = new ethers.Contract(
      process.env.REACT_APP_SERVGARD_CONTRACT!,
      Servgard.abi,
      signer
    );
    return contract;
  };

  const sendSms = async (event) => {
    const jobId = parseInt(event.target.id);
    try {
      const phoneNumber = props.project?.jobs.find(
        (job) => job.id === jobId
      )?.phone;
      if (!phoneNumber) {
        console.log("Need to throw an error here");
      } else {
        await api.sendSmsWhenJobDone({
          phoneNumber: phoneNumber,
        });
        alert("Sms has been sent");
      }
    } catch (error: any) {
      console.error(error);
      props.setErrorMessage(error.message);
    }
  };

  const setJobResolved = async (event) => {
    const jobId = parseInt(event.target.id);
    if (event.target.checked) {
      const contract = await getContract();
      try {
        const tx = await contract.jobUpdate(
          props.project?.id,
          jobId,
          JobStatus.Resolved
        );
        await tx.wait();
        await props.getProjects();
      } catch (error: any) {
        console.log(error);
        props.setErrorMessage(error.message);
      }
    }
  };

  const setJobPaid = async (event) => {
    const jobId = parseInt(event.target.id);
    const job = props.project?.jobs.find((job) => job.id === jobId);
    if (!job) {
      return;
    }
    if (job.status === JobStatus.Resolved && event.target.checked) {
      const contract = await getContract();
      try {
        const tx = await contract.sendPayment(
          props.project?.id,
          job.id,
          job.paymentAddress,
          ethers.utils.parseEther(ethers.utils.formatUnits(job.amount, 0))
        );

        await tx.wait();
        await props.getProjects();
      } catch (error: any) {
        console.log(error);
        props.setErrorMessage(error.message);
      }
    } else {
      //TODO should give a message say not ready for payment
    }
  };

  const setCriteria = async (event) => {
    const jobId = parseInt(event.target.id.split("-")[0]);
    const criteriaId = parseInt(event.target.id.split("-")[1]);
    const contract = await getContract();
    try {
      await (
        await contract.criteriaUpdate(
          props.project?.id,
          jobId,
          criteriaId,
          !event.target.checked
        )
      ).wait();
      await props.getProjects();
    } catch (error: any) {
      console.log(error);
      props.setErrorMessage(error.message);
    }
  };

  return (
    <JobTableView
      projectJobs={props.project?.jobs}
      sendSms={sendSms}
      setJobResolved={setJobResolved}
      setJobPaid={setJobPaid}
      setCriteria={setCriteria}
    />
  );
};

export default JobTableLogic;
