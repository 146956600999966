export enum ProjectStatus {
  Created,
  Actived,
  Pending,
  Done,
}

export enum JobStatus {
  Created,
  Accepted,
  Active,
  Pending,
  Resolved,
  Paid,
  Done,
}

export function getValuesFromProjectStatusEnum(m: ProjectStatus) {
  return ProjectStatus[m];
}

export function getValuesFromJobStatusEnum(m: JobStatus) {
  return JobStatus[m];
}
