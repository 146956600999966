import { createContext } from "react";
import ServgardApi from "../apis/ServgardApi";

export const ApiContext = createContext<ServgardApi>(null as any);

export const ApiProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const api = new ServgardApi(process.env.REACT_APP_API_URL!, true);

  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};
