/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
//import { providers } from "ethers";
import { Disclosure } from "@headlessui/react";
import { Button } from "..";

type Props = {
  connectMetamask(): void;
  account: string | undefined;
  disconnectWallet(): void;
};

const NavBarView: React.FC<Props> = Props => {
  return (
    <Disclosure as="nav" className="bg-primary-dark ">
      <div className="relative flex items-center justify-between h-16 shadow-2xl rounded-lg">
        <div className="flex items-center px-2">
          <div className="flex-shrink-0">
            <label className="text-lg font-extrabold font-sans">
              <img
                className="content-center items-center h-14"
                src="images/servgard.png"
                alt="Servgard"
              />
            </label>
          </div>
        </div>
        <div className="flex space-x-4">
          {Props.account ? (
            <Button onClick={Props.disconnectWallet}>
              Disconnect
            </Button>
          ) : (
            <Button onClick={Props.connectMetamask}>
              Connect Wallet
            </Button>
          )}
        </div>
      </div>
    </Disclosure>
  );
};

export default NavBarView;
