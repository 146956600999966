import React from "react";
import AdminView from "./Admin_View";

const AdminLogic: React.FC = () => {
  return (
    <AdminView 
    />
  );
};

export default AdminLogic;
