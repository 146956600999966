import React from "react";
import { JobStatus, getValuesFromJobStatusEnum } from "../../Types/Status";

type Props = {
  projectJobs: api.Job[] | undefined;
  sendSms(event): void;
  setJobResolved(event): void;
  setJobPaid(event): void;
  setCriteria(event): void;
};

const JobTableView: React.FC<Props> = (props) => {
  const borderColor = "border-gray-600";

  type CellProps = {
    className?: string;
  };
  const HCell: React.FC<CellProps> = (props) => {
    return (
      <div
        className={`text-center self- break-words bg-gray-400 ${props.className}`}
      >
        {props.children}
      </div>
    );
  };

  const Cell: React.FC<CellProps> = (props) => {
    return (
      <div
        className={`text-center break-words ${props.className} overflow-hidden`}
      >
        {props.children}
      </div>
    );
  };

  return (
    <div className="grid grid-cols-8 gap-0">
      <div
        className={`flex grid grid-cols-11 col-span-7 gap-0 border-2 ${borderColor}`}
      >
        <HCell className="col-span-3">Task</HCell>
        <HCell>Resolved</HCell>
        <HCell>Payment</HCell>
        <HCell>Value</HCell>
        <HCell>Status</HCell>
        <HCell className="col-span-4">Address</HCell>
        <div className="col-span-1" />
      </div>
      <div className="flex grid grid-cols-8 col-span-8">
        {props.projectJobs?.map((job, index) => {
          let rowClass = "";
          index % 2 === 0
            ? (rowClass += "bg-gray-100")
            : (rowClass += "bg-gray-200");

          const criteria = job.criteria?.map((criteria, index) => {
            const bottomBorder =
              index + 1 === job.criteria?.length
                ? `border-b-2 ${borderColor}`
                : "";
            return (
              <div
                className={`grid grid-cols-11 col-span-7 ${bottomBorder}`}
                key={`${job.id}-${criteria.id}`}
              >
                <Cell className={`${rowClass} col-span-3 font-light`}>
                  {criteria.name}
                </Cell>
                <Cell className={rowClass}>
                  <input
                    id={`${job.id}-${criteria.id}`}
                    type="checkbox"
                    checked={criteria.status}
                    onChange={props.setCriteria}
                    disabled={
                      job.status === JobStatus.Resolved ||
                      job.status === JobStatus.Paid ||
                      job.status === JobStatus.Done
                    }
                  />
                </Cell>
                <Cell className={`${rowClass} col-span-7`}></Cell>
              </div>
            );
          });
          const bottomBorder = criteria?.length === 0 ? `border-b-2` : "";
          const disableResolved = !job.criteria?.every(
            (criteria) => criteria.status === true
          );

          return (
            <div className={`grid grid-cols-8 col-span-8`}>
              <div
                className={`grid grid-cols-11 col-span-7 border-l-2 border-r-2 items-center ${borderColor} ${bottomBorder} ${rowClass}`}
              >
                <Cell className={`${rowClass} col-span-3`}>{job.name}</Cell>
                <Cell className={rowClass}>
                  <input
                    id={job.id.toString()}
                    type="checkbox"
                    checked={job.status >= JobStatus.Resolved}
                    onChange={props.setJobResolved}
                    disabled={
                      disableResolved ||
                      job.status === JobStatus.Paid ||
                      job.status === JobStatus.Done
                    }
                  />
                </Cell>
                <Cell className={rowClass}>
                  <input
                    id={job.id.toString()}
                    type="checkbox"
                    checked={job.status >= 5}
                    onChange={props.setJobPaid}
                    disabled={job.status !== JobStatus.Resolved}
                  />
                </Cell>
                <Cell className={rowClass}>
                  {(job.amount * 0.95).toLocaleString()} SVG
                </Cell>
                <Cell className={rowClass}>
                  {getValuesFromJobStatusEnum(job.status)}
                </Cell>
                <Cell className={`${rowClass} col-span-4 pr-2`}>
                  {job.paymentAddress}
                </Cell>
              </div>
              {job.status === JobStatus.Paid && (
                <div className="m-1 place-self-center">
                  <button
                    id={job.id.toString()}
                    className={`bg-white rounded-lg px-5 border-2 ${borderColor} mx-2`}
                    onClick={props.sendSms}
                  >
                    SMS
                  </button>
                </div>
              )}
              <div className="grid grid-cols-8 col-span-8">
                <div
                  className={`grid grid-cols-7 col-span-7 border-l-2 border-r-2 ${borderColor}`}
                >
                  {criteria}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default JobTableView;
