import React, { useState } from "react";
import ProjectView from "./Project_View";
import { BigNumber, ethers } from "ethers";
import Servgard from "../../build/contracts/Servgard.sol/Servgard.json";
import useWallet from "../../hooks/useWallet";
import { ProjectStatus } from "../../Types/Status";

type LoadingType = {
  createProject: boolean;
  refreshProject: boolean;
  updateProject: boolean;
};

const ProjectLogic: React.FC = () => {
  const [projects, setProjects] = useState<api.Project[]>();
  const [currentProject, setCurrentProject] = useState<api.Project>();
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<LoadingType>({
    createProject: false,
    refreshProject: false,
    updateProject: false,
  });

  const { getSigner } = useWallet();
  const getContract = async () => {
    const signer: any = await getSigner();
    const contract = new ethers.Contract(
      process.env.REACT_APP_SERVGARD_CONTRACT
        ? process.env.REACT_APP_SERVGARD_CONTRACT
        : "",
      Servgard.abi,
      signer
    );
    return contract;
  };

  const getProjects = async () => {
    setLoading({
      createProject: false,
      refreshProject: true,
      updateProject: false,
    });
    setErrorMessage(undefined);
    setLoadingData(true);
    const contract = await getContract();
    try {
      const projectCount = await contract.getProjectCount();
      let projects: api.Project[] = [];
      for (let i = 0; i < projectCount; i++) {
        const project = await contract.getProject(i);
        const jobs = project[1].map((job, index) => ({
          id: index,
          status: job[1],
          name: job[0],
          paymentAddress: job[2],
          amount: job[3],
          email: job[4],
          phone: job[5],
        }));

        for (const job of jobs) {
          const criteria = await contract.getCriteria(i, job.id);
          job.criteria = criteria.map((criteria, index) => ({
            id: index,
            name: criteria[0],
            status: criteria[1],
          }));
        }

        projects.push({
          id: i,
          name: project[0][0],
          status: project[0][1],
          jobs: jobs,
        });
      }
      setProjects(projects);
      setCurrentProject(
        projects.find((p) => p.id === currentProject?.id) ?? projects[0]
      );
      setLoadingData(false);
    } catch (error) {
      setLoadingData(false);
      console.log(error);
      setErrorMessage("Error retrieving data.  Please connect your wallet");
    }

    setLoading({
      createProject: false,
      refreshProject: false,
      updateProject: false,
    });
  };

  const createNewProject = async () => {
    const contract = await getContract();
    try {
      const projectCount = await contract.getProjectCount();

      await (
        await contract.createProject(
          `Mount Ommaney SS GLA ${projectCount}`,
          ["Civil works", "Inground Water and Drainage"],
          [
            "0x613AF1dB957106d0ceDc4f7D295a85a4E79B3BA4",
            "0x613AF1dB957106d0ceDc4f7D295a85a4E79B3BA4",
          ],
          [BigNumber.from(120000), BigNumber.from(80000)],
          ["test@dltx.io", "test@dltx.io"],
          ["0417254352", "0417254352"],
          [0, 0, 1, 1],
          [
            "Stormwater incl. Detention Tank",
            "DWG C-001, C002, C003",
            "Inground Drainage DWG H001, H002, H003",
            "Inground Water DWG H006, H007",
          ]
        )
      ).wait();
      await getProjects();
    } catch (error) {
      console.log(error);
    }
  };

  const updateProject = async (projectId: number | undefined) => {
    setLoading({
      createProject: false,
      refreshProject: false,
      updateProject: true,
    });
    const contract = await getContract();
    try {
      await (
        await contract.projectUpdate(projectId, ProjectStatus.Done)
      ).wait();
    } catch (error: any) {
      console.log(error);
      setErrorMessage(error.message);
    }
    setLoading({
      createProject: false,
      refreshProject: false,
      updateProject: false,
    });
    await getProjects();
  };

  return (
    <ProjectView
      getProjects={getProjects}
      projects={projects}
      currentProject={currentProject}
      setCurrentProject={setCurrentProject}
      loadingData={loadingData}
      errorMessage={errorMessage}
      createNewProject={createNewProject}
      loading={loading}
      updateProject={updateProject}
      setErrorMessage={setErrorMessage}
    />
  );
};

export default ProjectLogic;
